import { RoutePattern } from "../../routes/RoutePattern";

export const data = [
  {
    name: "home",
    to: "/",
    isRoute: true,
  },
  {
    name: "music",
    to: "/" + RoutePattern.Music,
    isRoute: true,
  },
  {
    name: "bio",
    to: "/" + RoutePattern.Bio,
    isRoute: true,
  },
];

import { data } from "./data";
import { Link as ScrollLink } from "react-scroll";
import styled from "styled-components";
import { RoutePattern } from "../../routes/RoutePattern";
import { useEffect, useState } from "react";
import { PATHNAME } from "../../constants";
import { Box, Image } from "../StyledComponent";
import SocialIcons from "../SocialIcons/SocialIcons";
import LOGO_IMG from "../../assets/images/logo.png";

const Container = styled.div`
  z-index: 1231231;
`;

export default function Navigation() {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [scrolled, setScrolled] = useState<boolean>(false);

  const handleClick = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    window.onscroll = (e) => {
      const scrollY = window.scrollY;
      setScrolled(scrollY >= 50);
    };
  }, []);

  const menuItems = data.map((item, idx) =>
    // this statement is application only if we're at home
    item.isRoute ? (
      <ScrollLink
        key={idx}
        to={item.name} // id of the section
        smooth
        duration={1000}
        spy={true}
        className="mr-10 last:mr-0 cursor-pointer text-xl md:text-2xl"
      >
        {item.name}
      </ScrollLink>
    ) : (
      <a
        key={idx}
        href={item.to} // route
        className="mr-2 md:mr-5 text-2xl"
      >
        {item.name}
      </a>
    )
  );

  return (
    <Container
      className={`${
        scrolled ? "text-white bg-black bg-opacity-60" : "text-black bg-none"
      } mx-auto flex flex-col justify-center items-center px-5 fixed top-0 left-0 w-full py-5 lg:px-24`}
    >
      <div className="w-full flex justify-center items-center">
        <ScrollLink
          to={RoutePattern.Home}
          className="text-2xl font-bold md:text-5xl cursor-pointer mr-auto"
        >
          T-SHYNE
        </ScrollLink>
        {/* desktop navigation */}
        <div className="uppercase justify-between items-center flex-row flex">
          {menuItems}
        </div>
        {/* tablet navigation */}
        {/* <div
          className={`uppercase justify-center items-center flex-col fixed ${
            isActive ? 'right-0' : '-right-full'
          } right-0 top-0 flex bg-white bg-opacity-30 h-full px-10 z-0 transition-all`}
        >
          {menuItems}
        </div>
        <div className="flex flex-col z-50" aria-label="button" onClick={handleClick}>
          <div
            className={`w-6 h-0.5 md:h-1 bg-white rounded-sm ${
              isActive ? 'rotate-45' : 'rotate-0'
            } transition-all`}
          />
          <div
            className={`w-6 h-0.5 md:h-1 bg-white rounded-sm mt-1 ${isActive ? 'hidden' : 'block'}`}
          />
          <div
            className={`w-6 h-0.5 md:h-1 bg-white rounded-sm mt-1 ${
              isActive ? '-rotate-45 mt-0 absolute' : 'rotate-0'
            } transition-all`}
          />
        </div> */}
      </div>
    </Container>
  );
}

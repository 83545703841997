import { motion } from "framer-motion";
import styled from "styled-components";
import { ArrowTypes } from "./types";

export const MusicDetail = styled(motion.div)`
  width: 400px;
  @media (max-width: 1368px) {
    width: 300px;
  }
`;

export const MusicImage = styled(motion.a)`
  transition: all 0.3s ease;
  overflow: hidden;
  width: 400px;
  text-align: right;

  :hover img {
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  @media (max-width: 1368px) {
    width: 300px;
  }
`;

export const Arrow = styled.button<ArrowTypes>`
  top: 110%;
  left: ${(props) => props.left && "45%"};
  right: ${(props) => props.right && "45%"};
  position: absolute;
  z-index: 2;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 2rem;
  font-weight: 100;
  outline: none;
  color: white;
  :focus {
    outline: none;
  }

  @media (min-width: 768px) {
    top: 100%;
    left: ${(props) => props.left && "45%"};
    right: ${(props) => props.right && "45%"};
  }

  @media (min-width: 1024px) {
    top: 50%;
    left: ${(props) => props.left && props.left};
    right: ${(props) => props.right && props.right};
  }
`;

export const SlideItem = styled.a`
  max-width: 350px;
  width: 100%;
  height: auto;

  @media (min-width: 768px) {
    max-width: 800px;
    height: 70vh;
    /* max-width: 700px; */
  }

  @media (min-width: 1024px) {
    max-width: 1000px;
  }
`;

import {
  faApple,
  faFacebookF,
  faInstagram,
  faSoundcloud,
  faSpotify,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

interface SocialIconsTypes {
  icon?: any;
  link: string;
  img?: string;
  isPandora?: boolean;
}

export const data: SocialIconsTypes[] = [
  {
    icon: faInstagram,
    link: "https://www.instagram.com/tshyne_/",
  },
  {
    icon: faTwitter,
    link: "https://twitter.com/TShyne",
  },
  {
    icon: faFacebookF,
    link: "https://www.facebook.com/tshynemusic",
  },
  {
    icon: faApple,
    link: "https://music.apple.com/us/artist/t-shyne/301046170",
  },
  {
    icon: faSpotify,
    link: "https://open.spotify.com/artist/14qypx78D3Jd1mm7c2BqQb",
  },
  {
    icon: faYoutube,
    link: "https://www.youtube.com/user/TShyneMusic",
  },
  {
    icon: faSoundcloud,
    link: "https://soundcloud.com/tshyne",
  },
];

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  isBurgerActive: false,
  isLoaded: false,
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setIsBurgerActive: (state, { payload }: PayloadAction<boolean>) => {
      state.isBurgerActive = payload;
    },
    setVideoPopupState: (state, { payload }) => {
      state.isLoaded = payload;
    },
  },
});

export const { setIsBurgerActive, setVideoPopupState } = globalSlice.actions;

import { MotionProps } from "framer-motion";

const easing = [0.175, 0.25, 0.32, 0.46];

export const TransitionPowerOne = { duration: 0.8, ease: easing };
export const TransitionPowerTwo = {
  y: { stiffness: 1000, velocity: -100 },
};
export const TransitionPowerThree = {
  y: { stiffness: 1000 },
};

export const FadeInThenUp: MotionProps = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
  transition: { ...TransitionPowerOne },
};

export const FadeIn: MotionProps = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 1,
  },
  transition: { ...TransitionPowerOne },
};

export const fadeInFadeOut: MotionProps = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
  transition: {
    delay: 0.4,
    duration: 0.8,
    ...TransitionPowerThree,
  },
};

export const slideToRight: MotionProps = {
  initial: {
    opacity: 0,
    x: -20,
  },
  animate: {
    opacity: 1,
    x: 0,
  },
};

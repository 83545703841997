import MOBILEHERO_IMG from "../../../assets/images/hero.mobile.bg.png";
import HERO_IMG from "../../../assets/images/hero.desktop.bg.png";
import { Fluid } from "../../../components/StyledComponent";
import { RoutePattern } from "../../../routes/RoutePattern";

const Showcase = () => {
  return (
    <Fluid
      id={RoutePattern.Home}
      className="z-10"
      type="default"
      height="100vh"
      src={HERO_IMG}
      mobileBg={MOBILEHERO_IMG}
    ></Fluid>
  );
};

export default Showcase;

import { useState } from "react";
import { RoutePattern } from "../../routes/RoutePattern";
// import { Link } from './Link';

const Bio = () => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div
      id={RoutePattern.Bio}
      className="py-24 text-lg md:py-0 md:min-h-screen flex flex-col justify-center items-center bg-black text-white"
    >
      <div className=" mx-auto w-5/6 md:w-3/4 flex flex-col justify-center items-center">
        <p>
          Tafa Peters, better known as T-Shyne, is solidifying his spot in the
          rap game. A longtime friend and collaborator of Young Thug, T-Shyne
          signed to Thug’s Young Stoner Life Records/300 Entertainment in 2020.
          T-Shyne was born in Granada and raised in East Hampton, New York which
          served as his first introduction to what would soon become a career in
          the rap genre. He began rapping as a teenager and eventually moved to
          the Bronx to further his career in Hip Hop. In 2018, T-Shyne released
          his debut project The Immaculate, which included features from Young
          Thug, Juicy J, Slim Jxmmi and more, followed by several singles
          including notable tracks “Side Steppin” ft. Young Thug, “Slime” ft.
          Lil Keed, and “Layaway” ft. Alexander Lewis and Chief Keef. 
          {!open && <span>...</span>}
        </p>
        {!open ? null : (
          <div>
            <p className="mt-3">
              Last year, T-Shyne released his single “Moncler” with Young Thug,
              which served as his first official release as a member of the
              Young Stoner Life/300 Entertainment roster. As a member of the
              Young Stoner Life family, T-Shyne has also been a part of notable
              collaborations and some of the label's biggest moments including
              his appearance on Young Thug’s acclaimed On The Rvn EP, and Slime
              Language 2 - most notably with his appearance on “That Go!”
              alongside Young Thug and Meek Mill. Most recently, T-Shyne was
              featured on Young Thug’s #1 album PUNK on standout track
              “Stressed”, alongside J.Cole and Young Thug.
            </p>
            <p className="mt-3">
              Now, T-Shyne is laying the bricks for his next chapter in music.
              This year, he released “30 for 30”, the first single from his new
              project Confetti Nights. The mixtape was executive produced by
              Young Thug and NBA superstar Kevin Durant. Confetti Nights
              displays T-Shyne’s undeniable storytelling ability, elevated by
              features from YSL labelmates Young Thug and Gunna. As he continues
              to cement his signature lyrical style, T-Shyne is creating his own
              space in the mainstream
            </p>
          </div>
        )}
        <button
          className="bg-white text-black py-2 px-10 mt-5"
          onClick={() => setOpen(!open)}
        >
          {!open ? "Read More" : "Show Less"}
        </button>
      </div>
    </div>
  );
};

export default Bio;

import { HtmlHTMLAttributes } from "react";
import styled from "styled-components";
import { primary } from "../../constants";

const H2 = styled.h2`
  position: relative;

  :after {
    content: "";
    width: 110%;
    left: -5%;
    z-index: -1;
    position: absolute;
    bottom: 5%;
    height: 0.6rem;
    background-color: ${primary};
  }
`;

type TextProps = HtmlHTMLAttributes<HTMLHeadingElement> & {
  level?: number;
  className?: string;
};

const Text = ({ level, className, ...props }: TextProps) => {
  return (
    <span className={`${className}`} {...props}>
      {props.children}
    </span>
  );
};

export default Text;

import { MusicSliderDataTypes } from "../../../components/MusicSlider/types";

export const data: MusicSliderDataTypes[] = [
  {
    title: "T-Shyne - Top 5",
    link: "https://tshyne.ffm.to/top5",
    img: "https://res.cloudinary.com/feature-fm/image/fetch/s--0sQRjeL_--/f_auto/https%3A%2F%2Fimagestore.ffm.to%2Flink%2F68e2feca90230235ab56be47c0d6ef4a.jpg",
  },
  {
    title: "T-Shyne - Confetti Nights",
    link: "https://tshyne.ffm.to/confettinights",
    img: "https://res.cloudinary.com/feature-fm/image/fetch/s--Jyx7pNyT--/f_auto/https%3A%2F%2Fimagestore.ffm.to%2Flink%2F090ff71b6a15bfdd1420960001b4ed7d.jpg",
  },
];

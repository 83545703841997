import { Bio } from "../../components";
import { Box } from "../../components/StyledComponent";
import { Music } from "./Music/Music";
import Showcase from "./Showcase/Showcase";

export default function Home() {
  return (
    <Box className="text-white w-full flex flex-col justify-center items-center">
      <Showcase />
      <Music />
      <Bio />
    </Box>
  );
}

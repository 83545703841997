import MusicSlider from "../../../components/MusicSlider/MusicSlider";
import { Box, Fluid } from "../../../components/StyledComponent";
import { RoutePattern } from "../../../routes/RoutePattern";
import { data } from "./data";

export const Music = ({}) => {
  return (
    <Box
      id={RoutePattern.Music}
      className="flex justify-center items-center py-24 md:py-0 md:min-h-screen"
    >
      <MusicSlider data={data} />
    </Box>
  );
};

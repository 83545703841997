import { Helmet } from "react-helmet";

export default function IndexHelmet() {
  const title = "T-Shyne";
  const description = "Stream the latest music from T-Shyne now";
  const url = "https://tyshne.700brands.com";

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="user-scalable=no,initial-scale=1,maximum-scale=1,minimum-scale=1,width=device-width,height=device-height,target-densitydpi=device-dpi"
      />
      <meta name="theme-color" content="#000000" />
      <meta name="description" content={description} />
      <meta name="apple-mobile-web-app-title" content={title} />

      <link rel="icon" type="image/png" href={`${url}/favicon.png`} />
      <link rel="apple-touch-icon" href={`${url}/favicon.png`} />
      <link rel="apple-touch-icon" sizes="76x76" href={`${url}/favicon.png`} />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href={`${url}/favicon.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="167x167"
        href={`${url}/favicon.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${url}/favicon.png`}
      />
      <link rel="icon" sizes="192x192" href={`${url}/favicon.png`} />
      <meta
        name="msapplication-square310x310logo"
        content={`${url}/favicon.png`}
      />

      {/* OG FB */}
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta name="apple-mobile-web-app-title" content={description} />

      {/* OG TWITTER */}
      <meta
        property="og:image"
        itemProp="image"
        content={`${url}/preview.png`}
      />
      <meta name="twitter:image:src" content={`${url}/preview.png`} />
      <meta itemProp="image" content={`${url}/preview.png`} />
      <meta name="image" content={`${url}/preview.png`} />
      <meta property="twitter_image" content={`${url}/preview.png`} />

      <title>{title}</title>
    </Helmet>
  );
}

import SocialIcons from "../SocialIcons/SocialIcons";
import { Anchor, Container, Text } from "../StyledComponent";

export default function Footer() {
  return (
    <Container
      id="footer"
      className="w-full px-24 text-lg py-2 mx-auto text-center bg-black"
    >
      <Text className="text-white text-center font-bold">&copy; T-Shyne</Text>
      <SocialIcons />
    </Container>
  );
}
